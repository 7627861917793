/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.top-bar {
  font-weight: 900;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #7c2a90;
  width: 100%;
  height: 60px;
  z-index: 350;
  padding: 0;
  -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.75);
          box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.75);
  background: -webkit-gradient(linear, left top, right top, from(#7c2a90), to(#93278f));
  background: -webkit-linear-gradient(left, #7c2a90 0%, #93278f 100%);
  background: linear-gradient(90deg, #7c2a90 0%, #93278f 100%);
}
.top-bar > div {
  -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.75);
          box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.75);
  background: -webkit-gradient(linear, left top, right top, from(#7c2a90), to(#93278f));
  background: -webkit-linear-gradient(left, #7c2a90 0%, #93278f 100%);
  background: linear-gradient(90deg, #7c2a90 0%, #93278f 100%);
}
.big-nav-item {
  text-transform: uppercase;
  font-size: 1.5em;
}
.float-left {
  float: left;
  height: 60px;
}
.float-left img {
  height: 40px;
  margin-left: 1em;
  margin-top: 10px;
}
.float-left:hover {
  filter: drop-shadow(0 0 3px violet);
}
ul.nav-menu {
  float: right;
  height: 100%;
  margin-top: 5px;
  font-size: 1.2em;
  border-bottom: 0;
  background-color: transparent;
  color: white;
  width: 80%;
  justify-content: end;
}
ul.nav-menu li.ant-menu-item a {
  color: white;
  text-decoration: none;
}
ul.nav-menu li.ant-menu-item-active,
ul.nav-menu li.ant-menu-submenu-active {
  border-bottom-color: violet !important;
  color: violet !important;
}
ul.nav-menu li.ant-menu-item-active a,
ul.nav-menu li.ant-menu-submenu-active a,
ul.nav-menu li.ant-menu-item-active span,
ul.nav-menu li.ant-menu-submenu-active span {
  color: violet !important;
}
ul > li.ant-menu-item a:hover {
  color: violet !important;
}
ul.ant-menu-sub > li.ant-menu-item a:hover {
  color: #7c2a90 !important;
}
@media (max-width: 720px) {
  ul.nav-menu {
    background-color: #7c2a90;
    background: -webkit-gradient(linear, left top, right top, from(#7c2a90), to(#93278f));
    background: -webkit-linear-gradient(left, #7c2a90 0%, #93278f 100%);
    background: linear-gradient(90deg, #7c2a90 0%, #93278f 100%);
    width: 100%;
    margin: 0;
    -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.75);
            box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.75);
  }
  .top-bar {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .top-bar .float-left {
    text-align: center;
    float: none;
  }
}
.top-bar nav ul li a {
  font-size: 1.2rem;
  vertical-align: super;
  line-height: 40px;
  margin-top: -0.6rem;
  color: white;
}
.top-bar nav ul li a:hover {
  color: violet;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.visualizer {
  position: absolute;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
@media (max-width: 720px) {
  .visualizer {
    position: relative;
  }
}
.visualizer img {
  width: 100%;
  cursor: pointer;
  /* filter: drop-shadow(0px 0px 10px black); */
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.vis-rect {
  width: 10px;
  height: 0;
  z-index: 9;
  top: 50%;
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  left: 50%;
  position: absolute;
  background-color: black;
}
.fading-meter {
  -webkit-transition: height 2s ease-out;
  transition: height 2s ease-out;
  height: 0 !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ocean-container {
  position: absolute;
  top: 90vh;
  left: 0;
  width: 100%;
  height: 17.5%;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  overflow: hidden;
}
@media (max-width: 720px) {
  .ocean-container {
    display: none;
  }
}
.ocean {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: visible;
  top: 0;
  left: 0;
}
.content {
  min-height: 88vh;
}
.center-box {
  width: 900px;
  max-width: 95%;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0.7)));
  background: -webkit-linear-gradient(bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.7) 100%);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.7) 100%);
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 15vh;
  position: absolute;
  border-radius: 2em;
  -webkit-box-shadow: 2px 2px 15px #000000b3;
          box-shadow: 2px 2px 15px #000000b3;
}
@media (max-width: 720px) {
  .center-box {
    display: block;
    margin-top: 12em;
    position: static;
    overflow: hidden;
    padding-bottom: 2em;
  }
}
.center-box::after {
  content: "";
  clear: both;
}
@media (max-width: 720px) {
  .parallax-banner {
    position: absolute !important;
    margin-top: -5vh !important;
    height: 140vh !important;
    z-index: -1;
  }
}
.mid-text {
  padding: 3em;
  text-align: center;
  display: block;
  font-size: 1.1em;
}
.mid-text h1 {
  font-size: 3.5em;
  font-weight: 700;
  color: #7c2a90;
  width: 100%;
}
.mid-text h2,
.mid-text h3,
.mid-text h4,
.mid-text h5,
.mid-text p {
  width: 60%;
}
@media (max-width: 720px) {
  .mid-text {
    width: 100%;
    padding-top: 3em;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 3em;
  }
  .mid-text h1 {
    font-size: 2.5em;
  }
  .mid-text h2 {
    width: 100%;
    font-size: 1em;
  }
  .mid-text p {
    width: 100%;
  }
}
.audio-viz {
  display: block;
  width: 250px;
  height: 250px;
  right: 5%;
  top: 30%;
  float: right;
}
@media (max-width: 720px) {
  .audio-viz {
    order: 1;
    min-width: 45vmin;
    min-height: 45vmin;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    top: 0;
    right: 0;
    float: none;
  }
}
.belowFold {
  margin: 0;
  margin-top: -5em;
  padding-bottom: 5em;
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(15%, #7c2a90), color-stop(60%, rgba(0, 0, 0, 0.15))), url("/assets/ScheduleBackground.png");
  background-image: -webkit-linear-gradient(top, #7c2a90 15%, rgba(0, 0, 0, 0.15) 60%), url("/assets/ScheduleBackground.png");
  background-image: linear-gradient(to bottom, #7c2a90 15%, rgba(0, 0, 0, 0.15) 60%), url("/assets/ScheduleBackground.png");
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 20vh;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.belowFold > div {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1000px;
  font-size: 1.2em;
}
.belowFold > div h1,
.belowFold > div h2,
.belowFold > div h3,
.belowFold > div h4,
.belowFold > div p {
  color: white;
}
@media (max-width: 720px) {
  .belowFold {
    margin-top: 40vh;
  }
}
.wave {
  background: url("/assets/wave.svg") repeat-x;
  position: absolute;
  top: 0;
  width: 6400px;
  height: 10vh;
  overflow: visible;
  background-size: contain;
  background-position: right top;
  margin-left: -900px;
  opacity: 1;
}
.wave:nth-of-type(2) {
  -webkit-animation: wave 12s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
          animation: wave 12s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 0.7;
}
@-webkit-keyframes wave {
  0% {
    translate: 0;
  }
  100% {
    translate: -30%;
  }
}
@keyframes wave {
  0% {
    translate: 0;
  }
  100% {
    translate: -30%;
  }
}
@-webkit-keyframes swell {
  0%,
  100% {
    -webkit-transform: translate3d(0, 30px, 0);
            transform: translate3d(0, 30px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes swell {
  0%,
  100% {
    -webkit-transform: translate3d(0, 30px, 0);
            transform: translate3d(0, 30px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 720px) {
  .header-title {
    top: 0;
    left: 0;
  }
}
.mid-social-media {
  margin-top: 1em;
}
.mid-social-media a {
  color: black;
  font-size: 2em;
  padding: 0.2em;
}
.mid-social-media a:hover,
.mid-social-media a:active {
  color: violet !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.bg-img {
  position: absolute;
}
.bg-img-outer {
  position: relative;
  z-index: -1;
}

